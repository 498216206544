<template>
    <div class="psiDiv" id="showLocation">
        <div class="aniHead">
        <el-row >
            <el-col :span=18 :offset="6">
            </el-col>
        </el-row>            
        </div>
        <div class="aniContent">
            
            <div class="menuDiv">
                <span>产品中心</span> / <span @click="turntoNews">{{menu.title}}</span> / <span>{{obj.title}}</span>
            </div>
            <div class="aniInfoDiv">
                <h3>{{obj.title}}</h3>
                <div class="dateDiv">{{obj.newDate}}</div>
                <div class="contentDiv cowise"  v-if="obj.title=='Nginxwise'"> 
                    <h4>企业数字化升级中遇到的挑战</h4>
                   <p>
                       <ol>
                           <li>统一应用入口网关
                               <ul>
                                   <li>配置复杂，专业性强，易出错</li>
                                   <li>配置文件无法批量修改且配置变更依赖重启操作</li>
                                   <li>缺乏统一的入口管理平台</li>
                                   <li>缺乏图形化管理界面</li>
                                   <li>缺乏发布管理工具</li>
                               </ul>
                           </li>
                           <li>安全护网
                               <ul>
                                   <li>护网扫描</li>
                                   <li>安全加固</li>
                                   <li>商业技术支持</li>
                               </ul>
                           </li>
                           <li>运维监控
                               <ul>
                                   <li>缺乏统一自动化运维</li>
                                   <li>集群监控工具</li>
                               </ul>
                           </li>
                            <li>CDN管理
                               <ul>
                                   <li>缺乏统一的CDN节点纳管和监控</li>
                               </ul>
                           </li>
                           <li>容器入口（Ingress Controller）
                               <ul>
                                   <li>缺乏容器入口的调优经验</li>
                                   <li>缺乏容器入口的商业支持</li>
                               </ul>
                           </li>

                       </ol>
                   <h4>应用场景一 统一配置、部署、集中入口管理</h4>
                   <div>
                       <img src="../../static/product/nginxwise1.png"/>
                   </div>
                    <p>
                        <ol>
                           <li>用户需求
                               <ul>
                                   <li>入口层LB和反向代理的统一管理</li>
                                   <li>NGINX资源池化、自动化调度</li>
                                   <li>统一网关配置管理：流量入口、负载分发、策略调整、上游流量管理、环境切换（灰度、蓝绿、金丝雀）</li>
                                   <li>容器平台入口的管理</li>
                                   <li>自动化运维和监控</li>
                               </ul>
                           </li>
                           <li>解决方案
                               <ul>
                                   <li>通过NGINXWISE 快速构建弹性伸缩的应用交付层，利用VE/LTM/LVS实现NGINX+集群的高可用，N+实现业务流量的灵活控制（URL分流、灰度发布、限流等）支撑业务需求</li>
                                   <li>NGINXWISE实现NGINX+集群的统一纳管，提供监控、配置管理、流量分析等能力</li>
                               </ul>
                           </li>
                           <li>客户收益
                               <ul>
                                   <li>提升应用交付的敏捷性，满足业务快速迭代、持续交付需求。</li>
                                   <li>丰富的灰度发布、限流等流量精细化控制解决方案满足应用定制化需求，提升业务高可用性同时降低生产运维风险</li>
                               </ul>
                           </li>
                        </ol>
                    </p>
                    <h4>应用场景二 核心业务系统多层近源防护</h4>
                    <p>
                          <ol>
                           <li>用户需求
                               <ul>
                                   <li>URL分流、灰度发布、限流等支撑业务需求</li>
                                   <li>等保要求，7x24安全服务能力保障</li>
                                   <li>网站/APP关键项信息脱敏，网站/APP关键项加密防篡改</li>
                                   <li>API安全加固和应用安全防护，API分级维度封装及能力开放 </li>
                                   <li>日志、统计、报表存档审计</li>
                               </ul>
                           </li>
                           <li>解决方案
                               <ul>
                                   <li>防护总控调度：接受N+发送的各类防护请求，完成清洗/加固的调度</li>
                                    <li>Web流量清洗区：通过AWAF提供网站安全防护，提供用户数据保护</li>
                                    <li>API东西防护区：通过AppProtect模块提供东西向API校验</li>
                                    <li>API南北加固区：通过AWAF提供南北向对外API内容/参数的深度加固</li>
                                    <li>平台管理中心：通过BIG-IQ/NC提供防护节点的管控</li>

                               </ul>
                           </li>
                           <li>方案优势
                               <ul>
                                   <li>分层、分域、分组的架构体系保证动态、灵捷、扩展</li>
                                    <li>分工明确，接入层支撑网站防护要求，路由层提供API防护</li>
                                    <li>高效专业，AWAF提供高级 Web 防护功能与流量学习AI能力</li>
                                    <li>逐步迭代，API防护由NAP提供，演进过程中由AWAF补充能力</li>
                               </ul>
                           </li>
                          </ol>
                    </p>
                    <div>
                       <img src="../../static/product/nginxwise2.png"/>
                   </div>
                   <h4>应用场景三 运维监控</h4>
                   <p>
                       <ol>
                           <li>
                               运维监控
                               <ul>
                                    <li>批量分组和快速扩容</li>
                                    <li>安装/升级</li>
                                    <li>配置文件修改、启停</li>
                                    <li>信息监控：TCP连接信息、in/out流量，QPS、1xx到5xx响应码、Cluster、upstream、rps、connections、zone等180+监控指标</li>
                                    <li>告警与短信/微信集成</li>
                                    <li>日志集成</li>
                                    <li>Open tracing集成</li>
                                    <li>API接口</li>
                                    <li>Ansible脚本集成</li>
                               </ul>
                           </li>
                           <li>
                              监控展示预警 
                           </li>
                       </ol>
                   </p>
                   <div>
                       <img src="../../static/product/nginxwise3.png"/>
                   </div>
                   <h4>应用场景四 CDN管理</h4>
                   <p>
                       <ol>
                           <li>
                               用户需求
                               <ul>
                                    <li>降低高昂的广域网专线费用</li>
                                    <li>降低访问时延，提升访问体验，优化应用架构</li>
                                    <li>健壮的弹性可扩展CDN架构</li>
                                    <li>灵活的客户端调度策略</li>

                               </ul>
                           </li>
                           <li>
                             解决方案
                             <ul>
                                <li>实现静态内容和动态内容的有效缓存</li>
                                <li>依托NGINX轻量、高性能、部署灵活、支持API等特性构建易管理、弹性可扩展的缓存集群</li>
                                <li>NGINXWISE CDN管理工具，实现CDN的有效管理、调度以及可视化，简化运维</li>
                             </ul>
                           </li>
                           <li>
                               客户收益
                               <ul>
                                <li>降低内网专线资源开销，降低资费</li>
                                <li>降低客户端访问时延，提升用户访问体验。实测静态网页加载效率提升25%+，视频网页加载效率提升50%+</li>
                                <li>弹性可扩展的解决方案，灵活的客户端访问调度策略</li>
 
                             </ul>
                           </li>
                       </ol>
                   </p>
                   <div>
                       <img src="../../static/product/nginxwise4.png"/>
                   </div>
                   <h4>
                       应用场景五 应用架构优化
                   </h4>
                   <p>
                       <ol>
                           <li>
                               用户需求
                               <ul>
                                    <li>某客户手机应用正计划做服务化改造，目前一个应用拆分为64个子应用</li>
                                    <li>从传统应用向服务化迁移的过程中，需满足业务高可用性、业务连续性要求的同时，降低迁移风险</li>
                                    <li>在秒杀等业务流量突发场景下确保应用系统的平稳运行</li>
                               </ul>
                           </li>
                           <li>
                             解决方案
                             <ul>
                                <li>通过NGINXWISE构建集群，构建轻量级的可弹性伸缩的应用交付层</li>
                                <li>NGINXWISE配置NGINX，实现业务流量的灵活控制（URL分流、灰度发布、限流等），支撑业务需求</li>
                                <li>NGINXWISE实现NGINX集群的统一纳管，提供监控、配置管理、流量分析等能力</li>

                             </ul>
                           </li>
                           <li>
                               客户收益
                               <ul>
                                <li>提升应用交付的敏捷性，满足业务快速迭代、持续交付需求</li>
                                <li>丰富的灰度发布、限流等流量精细化控制解决方案，满足应用定制化需求，提升业务高可用性的同时降低生产运维风险</li>
                             </ul>
                           </li>
                       </ol>
                   </p>
                   <div>
                      <img src="../../static/product/nginxwise5.png"/> 
                   </div>
                   <p style="color:#000">
                       <strong>试用申请 </strong>
                       <br/>E-mail：zhengfan.yang@couponcad.com
                   </p>
                </div>
                <div class="contentDiv cowise" v-if="obj.title=='Cowise'">
                    <h4>企业数字化升级中遇到的挑战</h4>
                    <p>
                        <ol>
                            <li>决策模板文件需要多个部门配合制作、填报，线下的方式，不利于数据计算模板文件的管理和使用</li>
                            <li>模板修改记录混乱，内部统一模板耗时耗力</li>
                            <li>利用excel内置插件进行计算，计算速度慢，且公式的可维护性很差</li>
                        </ol>
                    </p>
                    <h4>特色功能</h4>
                    <p>
                        <ol>
                            <li>流程定制
                               <div>根据实际业务需求，通过模板及权限控制实现流程自动化</div>
                            </li>
                            <li>与其他平台无缝对接
                                <div>输出标准化数据接口，可快速植入其他平台、系统</div>
                            </li>
                            <li>最大化保留用户习惯
                                <div>平台可以便捷地进行线上编辑计算模板，配置计算公式、标准，
                                    生成计算结果等，操作符合用户习惯</div>
                            </li>
                            <li>数据和计算模板在线管理和升级
                                <div>在原版本的基础上，升级一套新模板，可保留原模板的已有内容，
                                    根据需要进行微调，实现多版本管理</div>
                            </li>
                            <li>计算结果自动输出
                                 <div>提交数据文件，可根据模板自动输出标准化成果文件</div>
                            </li>
                        </ol>
                    </p>
                    <h4>功能展示</h4>
                    <p>
                         <ol style=" list-style:none;">
                            <li>1. 分类管理
                                <div>对模板进行分类管理，满足不同的业务需求。按类别管理，
                                    方便查找归类、批量删除等。
                                </div>
                            </li>
                         </ol>
                    </p>
                    <div>
                        <img src="../../static/product/cowise1.png"/>
                    </div>
                    <p>
                        <ol style=" list-style:none;">
                            <li>2. 模板制作
                                <div>进入具体某一个模板分类，可以对该类下的所有模板进行管理，
                                    创建新模板、升级现有模板、下载模板等</div>
                            </li>
                         </ol>
                    </p>
                    <div>
                        <img src="../../static/product/cowise2.png"/>
                    </div>
                    <p>
                        <ol style=" list-style:none;">
                            <li>3. 在线编辑
                                <div>类excel编辑器，支持400+公式，用户可直接上手编辑，操作便捷，
                                    学习成本低</div>
                            </li>
                         </ol>
                    </p>
                    <div>
                        <img src="../../static/product/cowise3.png"/>
                    </div>
                    <p>
                        <ol style=" list-style:none;">
                            <li>4. 权限分配
                                <div>对模板sheet进行分配，不同角色填报不同内容，制定不同标准</div>
                            </li>
                         </ol>
                    </p>
                    <div>
                        <img src="../../static/product/cowise4.png"/>
                    </div>
                    <p>
                        <ol style=" list-style:none;">
                            <li>5. 数据测算
                                <div>对不同角色上传的数据文件进行测算，得到标准化结果文件</div>
                            </li>
                         </ol>
                    </p>
                    <div>
                        <img src="../../static/product/cowise5.png"/>
                    </div>
                    <p style="color:#000">
                       <strong>试用申请 </strong>
                       <br/>E-mail：zhengfan.yang@couponcad.com
                   </p>
                </div>
                <div class="contentDiv cowise" v-if="obj.title=='Cloudwise'">
                   
                    <p>
                        CloudWise是一套可跨多云平台，提供数据迁移、备份、容灾的工具，可确保核心数据不丢失，业务不中断，故障秒恢复。
                    </p>
                    <h4>应用场景一 云平台备份</h4>
                    <p>
                        <ol>
                            <li>独立的私有云环境，有多种异构云平台的备份需求</li>
                            <li>适合超融合环境</li>
                        </ol>
                    </p>
                    <div>
                        <img src="../../static/product/cloundwise1.png"/>
                    </div>
                    <h4>应用场景二 混合环境备份</h4>
                    <p>
                        <ol>
                            <li>混合场景环境（大部分客户），需要对虚拟机和物理服务器 数据进行备份</li>
                            <li>通过统一的平台实现所有备份系统的管理，操作易用、简单</li>
                        </ol>
                    </p>
                    <div>
                        <img src="../../static/product/cloundwise2.png"/>
                    </div>
                    <h4>应用场景三  海量虚拟保护</h4>
                    
                    <div>
                        <img src="../../static/product/cloundwise3.png"/>
                    </div>
                    <p style="color:#000">
                       <strong>试用申请 </strong>
                       <br/>E-mail：zhengfan.yang@couponcad.com
                   </p>
                </div>
                 <div class="contentDiv cowise" v-if="obj.title=='Datawise'">
                   
                    <p style="text-indent:2em">
                        大数据平台需支持多应用管理，即支持对应用的服务级别管理（SLA）。能够实现应用的访问资源控制，支持资源隔离。
                        同时支持多租户功能，例如多租户管理、租户的操作员管理、租户的分等分级分组管理、租户的度量管理、租户的角色管理、租户应用授权、
                        租户数据隔离、租户的资源隔离等功能。
                       
                    </p>
                    <p style="text-indent:2em">
                         大数据平台应具有统一运维监控方面，可以图形化的实现安全管理、用户管理、监控运维、服务调度、应用部署、资源管理、作业编排、
                        服务接口等。
                    </p>
                    <p style="text-indent:2em">
                        大数据平台应同时支持作业调度管理，即实现统一的作业调度与编排管理功能，支持使用工作流的可视化的方式对工作任务进行统一编排和调度。
                        同时支持作业的资源管理、流程管理、任务管理、数据管理、应用管理、租户管理、多ETL调度任务的部署和并行处理等功能。
                    </p>
                    <h4>全景图</h4>
                    <div>
                        <img src="../../static/product/datawise1.png"/>
                    </div>
                    <h4>优势</h4>
                    <p>
                        <ol>
                            <li>不同行业特性的数据采集清洗
                                <ul>
                                    <li>提供数据可视化采集报告</li>
                                    <li>多样化数据采集工具</li>
                                    <li>支持多类数据爬取工具</li>
                                    <li>成功采集行业数据源 > 40种</li>
                                </ul>
                            </li>
                             <li>平台计算存储性能出众
                                <ul>
                                    <li>单条查询速度 &gt; 3ms，在十亿+条日志量中查检索时间&lt;5s</li>
                                    <li>支持高效全量数据备份与聚合分析</li>
                                    <li>分布式存储设计，支持规模动态扩展</li>
                                    <li>支持实时数据研判处理</li>
                                </ul>
                            </li>
                             <li>完善的数据挖掘和模型算法
                                <ul>
                                    <li>已经开发和支持60多种行业算法，并且在实际环境应用与验证</li>
                                    <li>集成算法库，集成 Spark 计算引擎</li>
                                    <li>支持多种分布式算法和标准语言算法</li>
                                    <li>后续支持可视化应用建模中心</li>
                                </ul>
                            </li>
                            <li>开放标准的大数据环境
                                <ul>
                                    <li>技术标准开放，支持快速自主构建大数据应用与环境</li>
                                    <li>支持智能统一API接口</li>
                                    <li>支持与第三方主流BI无缝对接</li>
                                </ul>
                            </li>
                            <li>严格的数据安全防护
                                <ul>
                                    <li>支持多租户安全，在开放环境中确保数据的安全隔离</li>
                                    <li>支持基于数据表、字段的授权</li>
                                    <li>支持数据字段级的脱敏加密</li>
                                    <li>支持用户权限集中管理与分配</li>
                                </ul>
                            </li>
                            <li>针对不同行业开发的平台
                                <ul>
                                    <li>基于行业需求的多年积累</li>
                                    <li>平台采用互联网先进技术，与百度\阿里同等技术</li>
                                    <li>整合了行业的行业需求与技术特点</li>
                                    <li>具有多个真实应用案例</li>
                                </ul>
                            </li>
                        </ol>
                    </p>
                   
                    <p style="color:#000">
                       <strong>试用申请 </strong>
                       <br/>E-mail：zhengfan.yang@couponcad.com
                   </p>
                </div>
                <div class="contentDiv cowise" v-if="obj.title=='IoTWise'">
                   
                    <p style="text-indent:2em">
                        IotWise是基于边云协同的低代码PaaS开发平台，解决企业物联网应用开发难、迭代快的痛点，解决庞杂的流式数据处理、边云协同、人机协同的难点。
                    </p>
                    <h4>全景图</h4>
                    <div>
                        <img src="../../static/product/iotwise1.png"/>
                    </div>
                    <h4>优势</h4>
                    <p>
                        <ol>
                            <li>在已有的IOT平台上，实现全新价值
                                <ul>
                                    <li>网络管理</li>
                                    <li>设备管理</li>
                                    <li>数据存储</li>
                                    <li>大屏展示和分析</li>
                                </ul>
                            </li>
                            <li>应用的敏捷开发（DevOps）</li>
                             <li>提供完整的平台需要，实现各类应用的敏捷开发
                                <ul>
                                    <li>应用开发（Application Development）</li>
                                    <li>实时运行环境（Runtime environment）</li>
                                    <li>规则引擎（Rule engine）</li>
                                    <li>图形化开发（IDE） etc.</li>
                                </ul>
                            </li>
                             <li>擅长物联网流式数据的实时、海量数据处理，支持多协议，支持异步架构系统之间的数据打通
                            </li>
                            <li>支持边缘设备上的轻量化部署
                            </li>
                        </ol>
                    </p>
                   <div>
                        <img src="../../static/product/iotwise2.png"/>
                    </div>
                    <p style="color:#000">
                       <strong>试用申请 </strong>
                       <br/>E-mail：zhengfan.yang@couponcad.com
                   </p>
                </div>
                <div class="contentDiv cowise" v-if="obj.title=='APIGateway'">
                    <h4>业务痛点</h4>
                   <p style="margin:2px 0;">
                    1、缺乏面向业务快速响应需求，快速创建、组合、发布API 管理平台。
                   </p>
                   <p style="margin:2px 0;">
                    2、缺乏对各业务系统的API 全周期管理，缺乏统一的平台和方式进行监控、整合、维护企业内部海量的API。
                   </p>
                   <p style="margin:2px 0;">
                    3、缺乏对各系统对API监控告警
                   </p>
                   <p style="margin:2px 0;">
                    4、缺乏API快速开发方式
                   </p>
                   <p style="margin:2px 0;">
                    5、缺少API统一认证方式
                   </p>
                   <p style="margin:2px 0;">
                    6、需要加强API接口安全
                   </p>
                   <p style="margin:2px 0;">
                    7、需要建立API统一出口和使用规范
                   </p>
                   <p style="margin:2px 0;">
                    8、需要建立API数据管理平台的高可用平台，保障业务稳定
                   </p>
                   <h4>功能结构</h4>
                   <p>
                       <ol>
                           <li>提供API Gateway 集成和创建 API应用
                               <ul>
                                   <li>轻松连接 现有SOA、ESB 和旧应用程序，对主要业务系统中的核心IT能力（或资源）得到解耦，大幅降低对系统原厂的依赖</li>
                                   <li>自动通过实时业务逻辑创建数据 API，解耦后的核心能力以API接口的形式汇聚到平台上</li>
                                   <li>形成不断复用、扩展、更新的“应用服务中心”：驾驶仓中心，投资管理中心，员工事务中心，审批流程中心，内容发布中心，财务管理中心，风控中心，协同办公中心等。</li>
                               </ul>
                           </li>
                            <li>释放数据价值
                               <ul>
                                   <li>使 API 实现盈利并产生收入</li>
                                   <li>构建数字生态系统以增强业务价值</li>
                                   <li>通过分析和优化来创造效率</li>
                               </ul>
                            </li>
                            <li>保护企业开放API
                               <ul>
                                   <li>防范威胁和 OWASP 漏洞</li>
                                   <li>通过 SSO 和身份管理来控制访问</li>
                                   <li>为应用程序、移动和 IoT 提供端到端安全性</li>
                               </ul>
                            </li>
                       </ol>
                   </p>
                  <div>
                       <img src="../../static/product/apiwise1.png"/>
                   </div>
                   <div>
                        <img src="../../static/product/apiwise2.png"/>
                    </div>
                    <h4>典型建设案例</h4>
                    <div>
                        <img src="../../static/product/apiwise3.png"/>
                    </div>
                    <p>
                       <ol>
                           <li>用户需求：
                               <ul>
                                   <li>API入口层和应用流量负载层的统一管理</li>
                                   <li>API数据资源池化、自动化调度</li>
                                   <li>统一APIGATEWAY配置管理：流量入口、负载分发、策略调整、上游流量管理、环境切换（灰度、蓝绿、金丝雀）</li>
                                   <li>容器平台入口的管理</li>
                                   <li>自动化运维和监控</li>
                               </ul>
                           </li>
                            <li>API数据管理平台解决方案：
                               <ul>
                                   <li>通过构建弹性伸缩的API管理平台，实现高可用，实现业务流量的灵活控制（URL分流、灰度发布、限流等）支撑业务需求</li>
                                   <li>实现的各业务API统一纳管，提供导入、生成、发布、监控、配置管理、流量分析等能力。</li>
                               </ul>
                            </li>
                            <li>客户收益：
                               <ul>
                                   <li>提升应用交付的敏捷性，满足业务快速迭代、持续交付需求。</li>
                                   <li>丰富的API发布、限流等流量精细化控制解决方案满足应用定制化需求，提升业务高可用性同时降低生产运维风险。</li>
                                   <li>费用为同类硬件产品的1/3</li>
                               </ul>
                            </li>
                       </ol>
                   </p>
                   <p style="color:#000">
                      <strong>试用申请 </strong>
                      <br/>E-mail：zhengfan.yang@couponcad.com
                  </p>
               </div>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    name:"aboutNewsInfo",
    data(){
        return{
            menu:{},
            obj:{},
            list:[
                {
                    title:"Nginxwise",
                    id:"s1" 
                },
                {
                    title:"Cowise",
                    id:"s2" 
                },
                {
                    title:"Cloudwise",
                    id:"s3"             
                },
                {
                    title:"Datawise",
                    id:"s4"             
                },
                {
                    title:"IoTWise",
                    id:"s5"             
                },
                {
                    title:"APIGateway",
                    id:"s6"             
                }
            ]
        }
    },
    methods:{
        init(){
            let id=this.$route.query.id

            for(let i=0;i<this.list.length;i++){
                let item=this.list[i]
                if(item.id==id){
                    this.obj=item
                    break
                }
            }
            let menu={}
            menu.type="产品中心"
            let title=this.$route.query.page
            if(title=="self"){
                menu.title="自研产品"
            }else if(title=="all"){
                menu.title="产品中心"
            }else if (title=="agent"){
                menu.title="代理产品"
            }
            this.menu=menu
            this.$nextTick(function(){
                document.querySelector('#showLocation').scrollIntoView(true);

            })
        },
        turntoNews(){
            history.go(-1)
        }
    },
    created(){
        this.init()
    }
}
</script>
<style>
.psiDiv{
    width:100%
}
 .psiDiv .aniHead{
    background-image: url("../../static/info/info1.png");
    height:280px;
    background-repeat: no-repeat;
    background-size: cover;
}
.psiDiv .aniHead .div1{
    height: 56px;
    font-size: 40px;
    font-weight: 800;
    color: #ffffff;
    line-height: 56px;
    margin-top: 90px;
}
.psiDiv .aniHead .div2{
    height: 30px;
    font-size: 22px;
    font-weight: 500;
    color: #f0f0f0;
    line-height: 30px;
    margin-top: 14px;
}
.psiDiv .aniContent{
    width:70%;
     margin:0 auto;
}
.psiDiv .menuDiv{
    padding:40px 0;
    font-size: 15px;
    color: #252C3D;
}
.psiDiv .menuDiv span:nth-child(2){
    color:#3C6AFB;
    cursor:pointer;
}
.psiDiv .menuDiv span:last-child{
    color:#848484;
}
.psiDiv .aniInfoDiv{
    padding:30px 40px;
    box-shadow: 0px 10px 20px 0px #f3f3f3;
    min-height:300px;
}
.psiDiv .aniInfoDiv .dateDiv{
   margin:10px 0;
   color:#848484;
   font-size:14px;
}
.psiDiv .aniInfoDiv .contentDiv{
    color:#848484;
    font-size: 14px;
    line-height:26px;
    margin:25px 0;
}
.contentDiv h4{
    color:#3f3f3f
}
.cowise img{
    width:100%
}

</style>